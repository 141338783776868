import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from "gatsby"
import Particles from 'react-particles-js';
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Button from '../../../components/shared/button'
// import Anchor from '../../../components/shared/link'
import Social, {SocialLink} from '../../../components/shared/social'
import { 
  HeroArea, 
  HeroBg, 
  ContetnTop, 
  ContentWrapper, 
  ContentBottom, 
  ContentBottomInner,
  ContentBottomLeft,
  ContentBottomRight,
  // ClientWrap,
  // ClientItem
} from './hero.style';
 
const Hero = (props) => {
  const heroQueryData = useStaticQuery(graphql `
    query ParticleHeroDataQuery {
      homeparticledataJson(id: {eq: "particle_hero_section_content"}) {
        title
        subtitle
        date
        btn_link

        social {
          facebook
          vkontakte
        }
        bg_image {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 950, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }  
  `);
  const heroData = heroQueryData.homeparticledataJson;
  const imageData = heroData.bg_image.childImageSharp.fluid;
  const {
    title,
    subtitle,
    // date,
    btn_link,
    // clients,
    social: { facebook, vkontakte }
  } = heroData;
  const particleOpt = {
    "particles": {
        "number": {
            "value": 50
        },
        "size": {
            "value": 3
        }
    },
    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "repulse"
            }
        }
    }
  }
  const {descStyle, headingStyle, socialStyle} = props
  return (
    <HeroArea> 
      <HeroBg fluid={imageData} backgroundColor={`#6f7c8e`}/>
      <Particles className="particle" params={particleOpt} />
      <ContentWrapper>
        <ContetnTop>
          <div className="col-3 offset-1">
            {/* {date && <Text {...descStyle}>{date}</Text>} */}
            {title && <Heading {...headingStyle}>{title}</Heading>}
            {subtitle && <Text {...descStyle}>{subtitle}</Text>}
            {btn_link && <Button as={Link} to={btn_link} layout={2}>ЗАКАЗАТЬ ЗВОНОК <i className="ti-arrow-right"></i></Button>}
          </div>
        </ContetnTop>
        <ContentBottom>
          <div className="col-4 offset-1">
            <ContentBottomInner>
              <ContentBottomLeft>
                <Text {...descStyle} mb="0">Ростов-на-Дону +7(950)868&#8209;44&#8209;44</Text>
              </ContentBottomLeft>
              <ContentBottomRight>
              <Social {...socialStyle}>
                {facebook && <SocialLink path={facebook}>Facebook</SocialLink>} 
                {vkontakte && <SocialLink path={vkontakte}>ВКонтакте</SocialLink>}
              </Social>
                {/* {clients && (
                  <ClientWrap>
                    {clients.map(client => (
                      <ClientItem key={client.id}>
                        <Anchor path={client.link}>
                          <img src={client.image.childImageSharp.fluid.src} alt="client"/>
                        </Anchor>
                      </ClientItem>
                    ))}
                  </ClientWrap>
                )} */}
              </ContentBottomRight>
            </ContentBottomInner>
          </div>
        </ContentBottom>
      </ContentWrapper>
    </HeroArea>
  )
}

Hero.propTypes = {
  descStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  socialStyle: PropTypes.object 
}

Hero.defaultProps = {
  descStyle: {
    color: '#fff',
    fontweight: 300,
    fonSize: '16px',
    texttransform: 'uppercase',
    letterspacing: '2px',
    mb: '48px'
  },
  headingStyle: {
    as: 'h1',
    color: '#fff',
    fontSize: '48px',
    mb: '24px',
    responsive: {
      xlarge: {
        fontSize: '33px'
      },
      large: {
        fontSize: '25px',
        mb: '40px'
      },
      medium: {
        fontSize: '20px'
      }
    }
  },
  socialStyle: {
    textAlign: "right",
		opacity: .7,
		space: 30,
		responsive: {
			medium: {
				space: 20,
				fontSize: '12px'
			}
		}
	}
}

export default Hero
 