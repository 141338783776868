import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Hero from '../containers/home-particle/hero'
import Story from '../containers/home-particle/story'
import ProjectSection from '../containers/home-particle/project'
import TestimonialSection from '../containers/home-particle/testimonial'
import About from '../containers/home-particle/about'
import VideoSection from '../containers/home-particle/video'
import BlogSection from '../containers/home-particle/feature-blog'
import BlogSection1 from '../containers/home-particle/blog'
import ContactFormSection from '../containers/contact/contact-form'

export default () => (
    <Layout>
        <SEO 
            title="6 шагов к эффективному маркетингу вашей компании" 
            description={
                `Хотите сделать сайт или подключить CRM? Запустить SMM и SEO? 
                Агентство полного цикла ostrovsky-marketing поможет добиться 
                успеха! Гарантия результата. Прозрачная отчетность. Всегда на связи!`
            }
        />
        <Hero />
        <Story />
        <About />
        <BlogSection1 />
        <ProjectSection />
        <VideoSection />
        <BlogSection />
        <TestimonialSection />
        <ContactFormSection/>
    </Layout>
)
 